import * as React from 'react'
import {graphql} from 'gatsby'
import {Link, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from "../components/Layout";


export const query = graphql`
  query ($language: String!) {

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Settings = () => {

  const {t} = useTranslation();
  const {languages, originalPath} = useI18next();

  return (
    <Layout pageTitle="Settings" showGoBack>
      <h1>{t("Settings")}</h1>
      {t("Language")}
      <ul className="languages">
        {languages.map((lng) => (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default Settings
